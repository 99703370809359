import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Service from '../components/Pages/Service';


function ServiceTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Service } />
    )

}

export const queryService = graphql`
fragment serviceFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            carousel{
                images
                title
                subTitle
            }
        }

    }
}
query serviceQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...serviceFields
    }
}`

export default ServiceTemplate


