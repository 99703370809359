import React from 'react'
import { Container, Row} from "react-bootstrap";
import { TextBlock } from "../Core";
import Carousel from "../Blocks/Carousel"
import Seo from '../seo'
import Breadcrumbs from "../Blocks/Breadcrumbs"

function Service(props) {
  const images = props.localImages.filter(localImage => props.content.images.includes(localImage.name))
  let parent = props.pages.filter((page) => page.type === 'services')[0]

    return (
        <>
        <Seo
            title={props.content.seoTitle || props.content.h1}
            description={props.content.seoDescription}
            metas={props.global.defaults.metas}
        />
      <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1} aUri={props.page.aUri} type='service' parentAUri={parent.aUri} parentH1={parent.content.h1}/>

        <Container style={{maxWidth: 1000, minHeight: 500}}>
    
        <div id='h1p'>
          <h1 style={{textAlign: 'center'}}>{props.content.h1}</h1>
          <p style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
        </div>
        <Carousel images={images} localImages={props.localImages} imageNames={props.content.images} title="" subTitle=""/>
        <Row style={{maxWidth: 800, margin: 'auto', marginTop: 80}}>
          <TextBlock>
        <div dangerouslySetInnerHTML={{ __html: props.content.text}} />
        </TextBlock>
      
        </Row>
      </Container>
      </>
    )
}

export default Service